import React from 'react';

import Hero from 'components/hero';
import DefaultLayout from 'layouts/default';

const title = 'Twoja wiadomość została wysłana';

const SuccessPage = () => (
  <DefaultLayout title={title} url="/sukces" withFooter={false}>
    <Hero title={title} withBorder />
  </DefaultLayout>
);

export default SuccessPage;
